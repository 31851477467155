import React from "react";
import EraseEvent from "./EraseNote";
import "../../../css/PNotes.css"


export default function DisplayEvent (props){
    return (
        <div className="noteSetUp">
            
            <div className="displayMessageNotes">
            {props.message} 
            </div>
            <div className="checkNote">
                <EraseEvent id={props.id}/>     
            </div>
               
        </div>
    )
}