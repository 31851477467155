import {getAuth, signOut } from "firebase/auth"
import '../css/Home.css'
import logo from '../images/logo.png'
import FirestoreEvents from "../components/firebase/Events/FirestoreEvents";
import FirestoreNote from "../components/firebase/PNotes/FirestoreNote"
import FirestoreLocation from "../components/firebase/Location/FirestoreLocation";
import S3 from "../components/S3/S3";
import { Airbnb } from "../components/airbnb/Airbnb";
export function Home(){
    const auth = getAuth();
    async function  HandleSignOut(){
                signOut(auth).then(()=>{
                console.log('signed out')
            }).catch((error)=>{
                console.log(error)
            })
        
    }
    return (
        <div className="wholePage">
            <div className="header">
                <img className='headerPhoto' src={logo} alt="HeaderImg" />
                <div className="bar">
                    <button onClick={()=>{HandleSignOut()}}>SignOut</button>
                </div>
            </div>
            <div className="notesandeventsLayout">
            <FirestoreEvents/>
            <FirestoreNote/>
            </div>
            
            <Airbnb/>
            <FirestoreLocation/>
            <S3/>
            <div className="Personal Notes">
                Personal Notes
            </div>
        </div>
        )
}