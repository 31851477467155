//import { async } from "@firebase/util"
import { useState } from "react"
import {signInWithEmailAndPassword, getAuth} from 'firebase/auth'
import '../css/signIn.css'
import patPhoto from '../images/pat.png'
//import { auth } from "../firebaseConfig"
import { useNavigate} from "react-router-dom"
//import { useAuth } from "../Context/AuthContext"

export function SignIn(){
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const auth = getAuth();
async function HandleSignIn(e) {
    e.preventDefault()
    signInWithEmailAndPassword(auth,email,password)
    .then(async (user) => { 
        console.log(user)
        //login({ user });
         navigate('/home')
    })
   .catch((error) => {
    console.log(error)
   })

}
 
async function HandleSignUp(){
    navigate('/signup')
    //return(<Navigate to="/signup" replace={true} />)
}

    return(
        <div className='signInContainer'>
            <div className='innerContainer'>
            <h1>The inner machinations of my mind are an enigma</h1>
            <form action="#">
                <input type="text" onChange={(e) => {setEmail(e.target.value)}} placeholder="email"  />
                <input type="password" onChange={(e) => {setPassword(e.target.value)}} placeholder="password"  />
                <div className="test">
                <button onClick={(e)=>{HandleSignIn(e)}}>Sign In</button>
                <button onClick={()=>HandleSignUp()}>Sign Up</button>
                </div>
                
            </form>
            </div>
            <img className='patPhoto' src={patPhoto} alt='my mind'/>
            
        </div>
        )
}