import { useEffect, useState } from 'react';
import '../../css/S3.css';
import axios from 'axios'

function S3() {
const [file, setFile] = useState()
const [description, setDescription] = useState("")
const [posts, setPosts] = useState([])

useEffect(()=> {
(async()=> {
  const result = await axios.get('/posts')
  setPosts(result.data.posts)
})()
},[])

const submit = async event => {
  event.preventDefault()
  const data = new FormData()
  data.append('image', file)
  data.append('description', description)
  const result = await axios.post('/posts', data)
  setPosts([result.data, ...posts])
  
}

  return (
    <div className="S3">
        <form className='s3Form' onSubmit={submit}>
          <input 
          className='S3Input'
           filename={file}
           onChange={e => setFile(e.target.files[0])}
           type="file"
           accept="image/*"
           ></input>
          <input 
          className='S3Input'
            onChange={e => setDescription(e.target.value)}
            type="text"
            placeholder='Description'
          ></input>
          <button className='S3Input' type="submit" >Submit</button>
        </form>

      <div className='postStuff'>
        {posts.map(post =>(
          <figure key={post.id}>
            <img src={post.image_url} alt="" loading='lazy'></img>
            <figcaption>{post.description}</figcaption>
          </figure>
        ))}
      </div>
      

    </div>
  )
}

export default S3;
