import { initializeApp } from "firebase/app";
//import { getAuth } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries
//const apiKey = process.env.FBAUTH_APIKEY
//const authDomain = process.env.FBAUTH_AuthDomain
/* const projectId = process.env.FBAUTH_PROJECTID
const storageBucket = process.env.FBAUTH_STORAGEBUCKET
const messagingSenderId = process.env.FBAUTH_MESAGINGSENDERID
const appId = process.env.FBAUTH_APPID
const measurementId = process.env.FBAUTH_MEASURMENTID */
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { getFirestore } from "firebase/firestore";

const fbConfig = {
  apiKey: "AIzaSyAb4T0lS_tLF62QtT6Vu39TkmbZtRkkZfs",
  authDomain: "borderhoppers-764c4.firebaseapp.com",
  projectId: "borderhoppers-764c4",
  storageBucket: "borderhoppers-764c4.appspot.com",
  messagingSenderId: "811487706092",
  appId: "1:811487706092:web:995da7546a58236ed31ec9",
  measurementId: "G-0DCSPZFZKQ"
};
export default fbConfig;
const app = initializeApp(fbConfig);
export const db = getFirestore(app);
// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//export const auth = getAuth(app);
