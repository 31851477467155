import React from "react";
import EraseLocation from "./EraseLocation";
import "../../../css/Location.css"


export default function DisplayEvent (props){
    return (
        <div className="locationSetUp">
            
            <div className="displayMessageLocation">
            {props.placeName} 
            
            </div>
            
            <div className="checkLocation">
            <a href= {props.link}><button>link</button> </a>
                <EraseLocation id={props.id}/>     
            </div>
               
        </div>
    )
}