import React,{useState} from "react";
import { collection, addDoc } from "firebase/firestore"; 
import { db } from "../../../firebaseConfig";
import 'react-datepicker/dist/react-datepicker.css';
//import { getAuth} from "firebase/auth";


export default function CreateEvent (){
    const [comment, setComment] = useState("")
   
    //const auth = getAuth();
    const createEvent =(e)=>{
        e.preventDefault();
        addDoc(collection(db, "Task"), {
            message: comment,
          });
          setComment("")
    };

      return(
        <div >
                    <form onSubmit={createEvent} className="modalContent">
                    
                <textarea 
                placeholder="be creative "
                className="eraseEvent"
                rows="4" 
                cols="50" 
                name="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                ></textarea>
               <div>
                 
               </div>
                
                    <button className="submit">&#091;add to notes&#093;</button>

                    </form>
                </div>
      )
};
// Add a new document with a generated id.
