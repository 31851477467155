import React,{useState} from "react";
import { collection, addDoc } from "firebase/firestore"; 
import { db } from "../../../firebaseConfig";
import 'react-datepicker/dist/react-datepicker.css';
import { getAuth} from "firebase/auth";


export default function CreateLocation (){
    const [placeName, setPlaceName] = useState("")
    const [link, setLink] = useState("")
   
    const auth = getAuth();
    const createLocation =(e)=>{
        e.preventDefault();
        addDoc(collection(db, "Location"), {
            placeName: placeName,
            by: auth.currentUser.displayName,
            link: link,
          });
          setLink("")
          setPlaceName("")
    };

      return(
        <div >
                    <form onSubmit={createLocation} className="modalContent">
                <input 
                placeholder="Place Name" 
                className="eraseLocation"
                value={placeName}
                onChange={(e) => setPlaceName(e.target.value)}
                ></input>
                <textarea 
                placeholder="Copy google maps link here"
                className="eraseLocation"
                rows="2" 
                cols="50" 
                name="link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                ></textarea>
               <div>
                 
               </div>
                
                    <button className="submit">&#091;add to Location&#093;</button>

                    </form>
                </div>
      )
};
// Add a new document with a generated id.
