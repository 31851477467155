//import { async } from "@firebase/util"
import { useState } from "react"
import { getAuth, createUserWithEmailAndPassword, updateProfile} from 'firebase/auth'
import '../css/signUp.css'
import regPhoto from '../images/reg.png'
import { useNavigate } from "react-router-dom"
//import { auth } from "../firebaseConfig"


export function SignUp(){
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('')
    const [token, setToken] = useState('')
    const auth = getAuth();
    const navigate = useNavigate();
    const secret = "Sandysagirl49";

async function HandleSignUp(e){
  if(token===secret){
    e.preventDefault();
    createUserWithEmailAndPassword(auth,email,password)
    .then((user) => { 
        //console.log(userCredential)
        updateProfile(user, {
            displayName: username
          }).then(() => {
            console.log("profile updated")
          }).catch((error) => {
            console.log(error.message)
          });
        navigate('/signin')
    }).catch((error) => {
    console.log(error)
   })
  }else{
    alert("Wrong Token");
  }
    

}
        return(
            <div className='signUnContainer'>
                <div className='innerContainer'>
                <h1>How Tough are ya</h1>
                <form action="#" >
                    <input type="text" onChange={(e) => {setUsername(e.target.value)}} placeholder="username"  />
                    <input type="text" onChange={(e) => {setToken(e.target.value)}} placeholder="Secret Sign up Token"  />
                    <input type="text" onChange={(e) => {setEmail(e.target.value)}} placeholder="email"  />
                    <input type="password" onChange={(e) => {setPassword(e.target.value)}} placeholder="password"  />
                    <button onClick={(e)=>{HandleSignUp(e)}}>SignUp</button>
                </form>
                </div>              
                <img className='regPhoto' src={regPhoto} alt='my mind'/>
            </div>
            )
}