import { createContext, useState, useEffect } from "react";
import { onAuthStateChanged, getAuth} from "firebase/auth";
//import { auth } from "../firebaseConfig";

export const Context  = createContext();

export function AuthContext({children}) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    
    useEffect (() => {
        let unsubscribe;
        const auth = getAuth();
        
        unsubscribe = onAuthStateChanged(auth, (currentUser) =>{
            setLoading(false)
            if(currentUser){ setUser(currentUser)}
            else{ setUser(null)}
        });
        return () => {
            if (unsubscribe) unsubscribe();
        }
        
    },[])
    const values = {
        user: user,
        setUser: setUser
    }

    return( <Context.Provider value={values}>
    { !loading && 
    children
    }
    </Context.Provider>)
    
} 