import React, {useEffect, useState} from "react";
import { onSnapshot } from "firebase/firestore";
import DisplayNote from "./DisplayNote";
import "../../../css/PNotes.css"
import Modal from 'react-modal';
import CreateNote from "./CreateNote";
import { collection } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
//import { getAuth } from "firebase/auth";

Modal.setAppElement('#root');

export default function FirestoreEvents (){
    const [posts, setPost] = useState([])
    const [modalIsOpen, setIsOpen] = React.useState(false);
    
    
    function openModal() {
        setIsOpen(true);
      }
    
    //  function afterOpenModal() {
        // references are now sync'd and can be accessed.
    //  }
    
      function closeModal() {
        setIsOpen(false);
      }

    useEffect(() => {
       // const auth = getAuth();
        const unsubscribe = onSnapshot(collection(db, "Task" ), snapshot => {
            setPost(snapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        return () => {unsubscribe()}
    },[])
    
    return(
        <div >
            <ul className="notesBoard">
                <div className="displayTitleNotesContainer">
                    <div className="displayTitleNotes">Task </div>
                    <button className="addNote" onClick={openModal}>+</button>
                    <div>
                    
                    <Modal
                        isOpen={modalIsOpen}
                        //onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        //style={customStyles}
                        contentLabel="Portal"
                        className="modal"
                        >
                    
                        <div className="eraseNote">NoteCreator</div>
                        <div>
                            
                        <CreateNote/>
                            <button className="eraseNote" onClick={closeModal}>&#091;close&#093;</button>
                        </div>
                        
                    </Modal>
             </div>
                </div>

            <br/>
                {posts.map(post =>(
                    <div key = {post.id} >
                        <DisplayNote
                        id={post.id}  
                        message={post.data.message}
                        />
                    </div>
                    
                ))}
                
            </ul>
        </div>
    )

}