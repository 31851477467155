import "../../css/airbnb.css"
import photoA from '../../images/airbnbi.webp'

export function Airbnb(){

    return(
        <div className="airbnbMain">
            <div className="airbnbText">Airbnb Info</div>
            <div className="barAirbnb"/>
            <div className="airbnbBasicInfo">
                <img className='airbnbPhoto' src={photoA} alt="AbnbImg" />
                <div className="innerTextA">
                    Address: 3762 Cedar Mountain Rd, Divide, CO 80814, USA 
                    <br/>{"    "}
                    <a href="https://www.google.com/maps/place/
                            38%C2%B058'43.0%22N+105%C2%B012'15.9%22W/@38.9786068,-105.2044204,
                            17z/data=!3m1!4b1!4m4!3m3!8m2!3d38.9786068!4d-105.2044204?entry=ttu">
                                <button>Maps</button>
                    </a>
                    {"     "}
                    <a href="https://www.airbnb.com/rooms/751405376987210186?check_in=2024-03-21&check
                            _out=2024-03-26&guests=1&adults=1&s=67&unique_share_id=78ba8863-afee-4e56-
                            9161-97c5d61dca9a">
                                <button>Airbnb Info</button>
                    </a>
                    <br/>check-In: Feb 8 at 5pm
                    <br/>check-out: Feb 12 at 10am
                    
                </div>
                

            </div>
            <div className="airbnbText" >Flight Information</div>
            <div className="barAirbnb"/>
            <div className="innerTextA" style={{marginLeft: 3 + 'vw'}}>
                <h1>Departing: 6:00am SAT  - 7:20am DEN </h1>
                Flight #: 114
                <br/>
                Aircraft: Boeing 737-800
                <br/>
                Flight Duration: 2hr 15min
                <h1>Returning: 7:00am DEN  - 10:15 am SAT </h1>
                Flight #: 370
                <br/>
                Aircraft: Boeing 737-700
                <br/>
                Flight Duration: 2hr 15min
            </div>
        </div>
    )
}