import React from "react";
//import { ReactDOM } from "react";
import ReactDom from 'react-dom/client'
import App from "./App";
import './css/index.css';
//import fbConfig from './firebaseConfig.js'
//import { initializeApp } from "firebase/app";

//const app = initializeApp(fbConfig)
ReactDom.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
)
