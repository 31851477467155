import React, {useEffect, useState} from "react";
import { onSnapshot } from "firebase/firestore";
import DisplayLocation from "./DisplayLocation";
import "../../../css/Location.css"
import Modal from 'react-modal';
import CreateLocation from "./CreateLocation";
import { collection } from "firebase/firestore";
import { db } from "../../../firebaseConfig";


Modal.setAppElement('#root');

export default function FirestoreLocation (){
    const [posts, setPost] = useState([])
    const [modalIsOpen, setIsOpen] = React.useState(false);
    
    
    function openModal() {
        setIsOpen(true);
      }
    
    //  function afterOpenModal() {
        // references are now sync'd and can be accessed.
    //  }
    
      function closeModal() {
        setIsOpen(false);
      }

    useEffect(() => {
       
        const unsubscribe = onSnapshot(collection(db, "Location" ), snapshot => {
            setPost(snapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        return () => {unsubscribe()}
    },[])
    
    return(
        <div >
            <div className="barAirbnb"/>

            <ul className="locationBoard">
            <div className="displayTitleLocationContainer">
                <div className="displayTitleLocation">Adventure to...</div>
                <button className="addLocation" onClick={openModal}>+</button>
                <div>
                    
                    <Modal
                        isOpen={modalIsOpen}
                        //onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        //style={customStyles}
                        contentLabel="Portal"
                        className="modal"
                        >
                    
                        <div className="eraseLocation">Add Destination</div>
                        <div>
                            
                        <CreateLocation/>
                            <button className="eraseLocation" onClick={closeModal}>&#091;close&#093;</button>
                        </div>
                        
                    </Modal>
             </div>
            </div>
            
            <br/>
                {posts.map(post =>(
                    <div key = {post.id} >
                        <DisplayLocation
                        id={post.id}  
                        placeName={post.data.placeName}
                        link = {post.data.link}
                        by = {post.data.by}
                        />
                    </div>
                    
                ))}
                
            </ul>
        </div>
    )

}