import './App.css';
import { SignIn } from './routes/SignIn';
import { SignUp } from './routes/SignUp';
import { Home } from './routes/Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthContext } from './Context/AuthContext';
import { Protected } from './routes/Protected';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      children:[
        {
          index:true,
          element: <SignIn/>,
        },
      {
        path: "signup",
        element: <SignUp/>
      },
      {
        path:"signin",
        element:<SignIn/>
      }
    ],
      
    },
    {
      path: "/home",
      element: <Protected><Home/></Protected>
    },

 

    
    
  ])
  return (
    <AuthContext>
      <RouterProvider router={router}></RouterProvider>
    </AuthContext>
  );
}

export default App;
