import React from "react";
import { doc, deleteDoc} from "firebase/firestore"; 
import { db } from "../../../firebaseConfig";
import "../../../css/PNotes.css"
//import { getAuth } from "firebase/auth";


export default function EraseEvent (props){
  //const auth = getAuth();
    const eraseEvent =()=>{
        const docRef =  doc(db, "Task", props.id)
        deleteDoc(docRef)
        .then(()=> console.log("Post Deleted"))
        .catch(error => console.log(error.message))
    };

      return(
        <div>
             <button onClick={eraseEvent} className="eraseNote">&#091;X&#093; </button>
        </div>
                   
      )
};
// Add a new document with a generated id.
